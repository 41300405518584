import React from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import Header from "../components/Header";

export default () => (
  <div className="container" style={{ padding: "24px" }}>
    <Header /> <br />
    <br />
    <h2>
      <strong>PRIVACY AND COOKIE POLICY</strong>
    </h2>
    <p>
      <strong>Data is registret at contact and when an order is given</strong>
    </p>
    <p>
      Fastpack will register your info when you visit us, contact us and/or
      place an order. The data is used to contact you, for providing a
      newsletter (if you have signed up for that), and for the service related
      to creating and delivering an order.
    </p>
    <p>
      <strong>Types of data:</strong>
    </p>
    <ul>
      <li>
        <p>First name</p>
      </li>
      <li>
        <p>Last name</p>
      </li>
      <li>
        <p>Contact info</p>
      </li>
      <li>
        <p>Company info</p>
      </li>
      <li>
        <p>Order related info</p>
      </li>
    </ul>
    <p>
      <strong>What happens with my data?</strong>
    </p>
    <p>
      The data is solely used for the purposes described in this section, and we
      won’t pass on or sell the data to third party companies.
    </p>
    <p>
      <strong>Cookies and privacy policy</strong>
    </p>
    <p>
      We use the cookies to register your preferences and deliver the best
      possible user experience.
    </p>
    <p>
      <strong>What is cookies?</strong>
    </p>
    <p>
      Cookies consist of portions of code installed in the browser when you
      visit a website. The cookies assist the website owner in providing the
      best possible service.
    </p>
    <p>Types of cookies:</p>
    <p>
      <strong>Technical cookies:</strong>
    </p>
    <p>
      Cookies that are necessary for the functioning of the service and
      operation of the website, and to enable you to easily log in and fill out
      forms.
    </p>
    <p>
      <strong>Analytical cookies:</strong>
    </p>
    <p>
      Enable the website owner to monitor and analyze web traffic and can be
      used to keep track of user behavior and optimize user experience. Cookies
      are also used to save browsing preferences and to optimize user
      experience.
    </p>
    <p>
      <strong>Third party cookies:</strong>
    </p>
    <p>
      Are used for marketing, campaign and product development purposes. These
      provide information on user behavior and preferences.markedsføringstiltag.
    </p>
    <p>
      <strong>Deleting cookies:</strong>
    </p>
    <p>
      You are always able to turn cookies off or delete them via the settings in
      your web browser.
    </p>
  </div>
);
